import { useGenerateDescription } from "@/features/summary-assist/api/useGenerateDescription";
import { useController } from "react-hook-form";
import classes from "@/features/summary-assist/DescriptionGenerator.module.scss";
import { Button, Icon } from "@salesforce/design-system-react";
import { FaRobot } from "react-icons/fa";
import { DESCRIPTION } from "@/features/listing/components/constants";
import { useRef } from "react";

export const SummaryAssistInput = ({ assetId }: { assetId: string }) => {
	const { field, fieldState } = useController({
		name: DESCRIPTION.path,
	});
	const { getDescription, isLoading } = useGenerateDescription(assetId);

	const ref = useRef<HTMLTextAreaElement>(null);

	const resizeTextarea = (el: HTMLTextAreaElement) => {
		const outerHeight = parseInt(window.getComputedStyle(el).height, 10);
		const diff = outerHeight - el.clientHeight;

		el.style.height = "0px";
		el.style.height = Math.max(36, el.scrollHeight + diff) + "px";
	};

	const generateNewSummary = async () => {
		const result = await getDescription();
		field.onChange(result);
		resizeTextarea(ref.current as HTMLTextAreaElement);
	};

	return (
		<>
			<div className={classes.webTab}>
				<div
					className={`slds-form-element ${
						fieldState.error ? "slds-has-error" : ""
					}`}
				>
					<label
						className="slds-form-element__label"
						htmlFor={DESCRIPTION.path}
					>
						Description
					</label>
					<textarea
						id={DESCRIPTION.path}
						name={DESCRIPTION.path}
						className="slds-textarea"
						onChange={(e) => {
							field.onChange(e);
							resizeTextarea(e.target);
						}}
						value={field.value ?? ""}
						ref={ref}
					></textarea>
					{fieldState.error && (
						<div className="slds-form-element__help" id="error-01">
							{fieldState.error.message}
						</div>
					)}
				</div>
				<Button
					onClick={generateNewSummary}
					style={{ alignSelf: "flex-end" }}
					variant="base"
				>
					{isLoading ? (
						<Icon
							name="spinner"
							category="utility"
							className={classes.spinner}
							size="x-small"
							colorVariant="default"
						/>
					) : (
						<FaRobot size={20} style={{ verticalAlign: "top" }} />
					)}
					<span style={{ paddingLeft: "0.25rem" }}>Generate Description</span>
				</Button>
			</div>
		</>
	);
};
