import { usePatchAssetMutation } from "@/features/asset-details-tab/api/endpoints";
import { Asset } from "@/features/asset/types";
import { useLazyGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { ReactNode, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormResetProvider } from "@/features/pricing-worksheet/providers/FormResetProvider";
import { FormSectionProvider } from "@/features/pricing-worksheet/providers/FormSectionProvider";
import { FormFocusProvider } from "@/features/pricing-worksheet/providers/FormFocusContext";
import { SubmitButtons } from "./SubmitButtons";
import { vestResolver } from "@hookform/resolvers/vest";
import { create, test } from "vest";
import { DESCRIPTION } from "@/features/listing/components/constants";
import { useListingConfirmationModal } from "./ListingConfirmationModal";
import { get } from "lodash";
import { webFormValidation } from "./webFormValidation";

type ListingDetails = {
	id?: string;
	marketingInfo: {
		listingDetails: Asset["marketingInfo"]["listingDetails"] | undefined;
	};
};

export const WebForm = ({
	assetId,
	children,
}: {
	assetId: string;
	children: ReactNode;
}) => {
	const selectListingDetails = (asset: Asset | undefined) => {
		return {
			id: asset?.id,
			marketingInfo: { listingDetails: asset?.marketingInfo?.listingDetails },
		};
	};
	const [fetch, { data, isFetching, isLoading, isSuccess, isUninitialized }] =
		useLazyGetAssetQuery({
			selectFromResult: ({ data, ...rest }) => {
				return { data: selectListingDetails(data), ...rest };
			},
		});

	const listingConfirmationModal = useListingConfirmationModal();

	const [confirmedDescription, setConfirmedDescription] =
		useState<boolean>(false);

	const methods = useForm({
		defaultValues: async (): Promise<ListingDetails> => {
			const { data: asset } = await fetch(assetId);
			return selectListingDetails(asset);
		},
		resolver: async (data, context, options) => {
			if (get(getFields(), DESCRIPTION.path) && !confirmedDescription) {
				try {
					await listingConfirmationModal.show({
						description: get(options.fields, DESCRIPTION.path).value,
					});
					setConfirmedDescription(true);
				} catch {
					return vestResolver<ListingDetails, undefined>(
						create(() => {
							test(DESCRIPTION.path, () => {
								return Promise.reject("Description confirmation required");
							});
						})
					)(data, context, options);
				}
			}
			return vestResolver<ListingDetails, undefined>(webFormValidation)(
				data,
				context,
				options
			);
		},
		reValidateMode: "onSubmit",
	});

	function getFields() {
		const fields = methods.formState.dirtyFields;
		return fields;
	}

	const [patch, { isFetching: isPatching }] = usePatchAssetMutation();

	const onSubmit = async (data: ListingDetails): Promise<void> => {
		await patch(data);
	};

	useEffect(() => {
		if (!isUninitialized) {
			setConfirmedDescription(false);
			methods.reset(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, isLoading, isPatching]);

	return (
		<FormProvider {...methods}>
			<FormResetProvider>
				<FormFocusProvider>
					<FormSectionProvider isEditable={true} isFetching={isFetching}>
						<div
							style={{
								position: "relative",
							}}
						>
							<div
								style={{
									backgroundColor: "white",
								}}
							>
								<form
									id="webForm"
									onSubmit={methods.handleSubmit(onSubmit)}
									style={{ padding: "1rem" }}
								>
									{isSuccess && children}
								</form>
								<SubmitButtons form="webForm" />
							</div>
						</div>
					</FormSectionProvider>
				</FormFocusProvider>
			</FormResetProvider>
		</FormProvider>
	);
};
