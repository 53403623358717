import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import config from "@/providers/config/config";
import { useIsUat } from "@/providers/roles/AccessHooks";
import {
	Button,
	Icon,
	PageHeader,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import classes from "./DescriptionGenerator.module.scss";
import { DescriptionGenerator } from "./DescriptionGenerator";
import { useState } from "react";

export const SummaryAssistWebForm = ({ assetId }: { assetId: string }) => {
	const [promptOpen, setPromptOpen] = useState<boolean>(false);

	const { data: asset, isSuccess } = useGetAssetQuery(assetId);

	const legacyLinkRoute =
		"LEGACY_AMP_URL" in config && config?.LEGACY_AMP_URL !== "#{LEGACY_AMP_URL}"
			? `${config?.LEGACY_AMP_URL}/vmf/remarketing/fieldmgr/AccountManager.cfm?account=${asset?.account?.accountNumber}&section=we`
			: `https://itg.cmhlink.net/vmf/remarketing/fieldmgr/AccountManager.cfm?account=${asset?.account?.accountNumber}&section=we`;

	const chevron = promptOpen ? "chevronup" : "chevrondown";
	// Action bar for Web Header

	const showPrompt = useIsUat();

	const actions = () => (
		<>
			<PageHeaderControl>
				{showPrompt && (
					<Button
						iconCategory="utility"
						iconName={chevron}
						iconPosition="right"
						label="Prompt"
						onClick={() => setPromptOpen(!promptOpen)}
					/>
				)}
				<a
					href={legacyLinkRoute}
					className="slds-button slds-button_neutral"
					target="_blank"
					rel="noreferrer"
				>
					Open in Link
				</a>
			</PageHeaderControl>
		</>
	);
	return (
		<>
			<PageHeader
				icon={
					<Icon
						assistiveText={{
							label: "Summary Assist",
						}}
						category="standard"
						name="flow"
						title="Summary Assist"
					/>
				}
				onRenderActions={actions}
				label="Summary Assist"
				title="Web"
				truncate
				variant="object-home"
			/>
			<div className={classes.descriptionContainer}>
				{isSuccess && (
					<DescriptionGenerator asset={asset} promptOpen={promptOpen} />
				)}
			</div>
		</>
	);
};
