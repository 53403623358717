import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { usePatchAssetMutation } from "../asset-details-tab/api/endpoints";
import { useAssetId } from "../asset/components/AssetIdProvider";
import { APPROVAL_STATUS_OPTIONS, EMPTY_PRICING_WORKSHEET } from "./constants";
import { PricingWorksheetType } from "./types";
import { FieldValues, SubmitHandler } from "react-hook-form";

export const usePricingWorksheet = (assetId: string) => {
	const { data: asset, isSuccess, isFetching } = useGetAssetQuery(assetId);
	const accountNumber = asset?.account?.accountNumber;
	const isHomeRepossessed = asset?.account?.accountStatus?.match(/^r$/i);

	const { currentIndex, setCurrentIndex } = useCurrentWorksheetParams();

	const [trigger, { isLoading }] = usePatchAssetMutation({
		fixedCacheKey: "pricingWorksheet",
	});

	const pricingWorksheets =
		isSuccess &&
		asset?.marketingInfo?.pricingWorksheets &&
		asset?.marketingInfo?.pricingWorksheets?.length
			? asset?.marketingInfo?.pricingWorksheets
			: [
					{
						...EMPTY_PRICING_WORKSHEET,
						marketingInfoId: asset?.marketingInfo?.id,
					},
			  ];

	const currentWorksheet = pricingWorksheets?.[currentIndex];

	const approvalStatus =
		APPROVAL_STATUS_OPTIONS[
			currentWorksheet?.approvalStatus === 2 && !isHomeRepossessed
				? 1
				: currentWorksheet?.approvalStatus
		];

	const onSubmit = async function (data: PricingWorksheetType) {
		const { approvalStatus: requestStatus } = data;

		const approvalStatus =
			(requestStatus === 2 && !isHomeRepossessed) || requestStatus === 0
				? 1
				: requestStatus;

		const update = {
			id: asset?.id,
			marketingInfo: {
				pricingWorksheets: [
					{
						...data,
						approvalStatus,
						marketingInfoId: asset?.marketingInfo?.id,
					},
					...pricingWorksheets.slice(1),
				],
			},
		};

		await trigger({
			...update,
			shouldCheckConcurrency: false,
		});
	} as SubmitHandler<FieldValues>;

	const goToCurrentWorksheet = () => {
		setCurrentIndex(0);
	};

	const createNewWorksheet = () => {
		const {
			id,
			approverId,
			approvedDate,
			approvalStatus,
			comments,
			createdDate,
			marketingInfoId,
			retailEstimates: {
				id: _reid,
				pricingWorksheetId: _repwid,
				concurrencyToken: _reCon,
				...retailEstimates
			},
			wholesaleEstimates: {
				id: _weid,
				pricingWorksheetId: _wepwid,
				concurrencyToken: _weCon,
				...wholesaleEstimates
			},
			...previous
		} = pricingWorksheets?.[0];

		const {
			id: _mscid,
			pricingWorksheetId: _mscpwid,
			concurrencyToken: _mscCon,
			...miscEstimates
		} = EMPTY_PRICING_WORKSHEET.miscEstimates;

		const update = {
			id: asset?.id,
			marketingInfo: {
				pricingWorksheets: [
					{
						...previous,
						approvalStatus: 1,
						retailEstimates,
						wholesaleEstimates,
						miscEstimates,
					},
					...pricingWorksheets,
				],
			},
		};
		trigger({ ...update, shouldCheckConcurrency: false });
	};

	const submitForApproval = () => {
		const update = {
			id: asset?.id,
			marketingInfo: {
				pricingWorksheets: [
					{ ...pricingWorksheets[0], approvalStatus: 2 },
					...pricingWorksheets.slice(1),
				],
			},
		};
		trigger({ ...update, shouldCheckConcurrency: false });
	};

	return {
		isHomeRepossessed,
		pricingWorksheets,
		currentWorksheet,
		currentIndex,
		isSuccess,
		submitForApproval,
		createNewWorksheet,
		approvalStatus,
		onSubmit,
		isFetching,
		isLoading,
		actuals: asset?.marketingInfo?.pricingWorksheetActuals,
		accountNumber,
		goToCurrentWorksheet,
	};
};

function useCurrentWorksheetParams() {
	const { assetId } = useAssetId();
	const [params, setParams] = useSearchParams();
	const { data: nWorksheets } = useGetAssetQuery(assetId, {
		selectFromResult: ({ data }) => {
			return { data: data?.marketingInfo?.pricingWorksheets?.length ?? 0 };
		},
	});

	const { worksheet } = Object.fromEntries(params.entries());

	const worksheetIndex = useMemo<number>(
		() =>
			(worksheet && Number(worksheet) < nWorksheets && Number(worksheet)) || 0,
		[nWorksheets, worksheet]
	);

	const setWorksheetIndex = (arg: number | ((index: number) => number)) => {
		const index = typeof arg === "number" ? arg : arg(worksheetIndex);

		setParams((params: URLSearchParams) => {
			params.append("worksheet", index.toString());
			return params;
		});
	};

	return { currentIndex: worksheetIndex, setCurrentIndex: setWorksheetIndex };
}
