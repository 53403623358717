import {
	Button,
	ButtonGroup,
	Icon,
	PageHeader,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { useSaleId } from "../SaleContext";
import { useBuyerModal } from "./BuyerModal";

export const BuyerHeader = () => {
	const { saleId } = useSaleId();
	const buyerModal = useBuyerModal();
	const actions = () => (
		<PageHeaderControl>
			<ButtonGroup
				className="slds-align-middle"
				variant="list"
				id="button-group-page-header-actions"
			>
				<Button
					label="Search Buyers"
					onClick={() => buyerModal.show({ saleId: saleId })}
				/>
			</ButtonGroup>
		</PageHeaderControl>
	);

	return (
		<PageHeader
			icon={
				<Icon
					assistiveText={{
						label: "Home",
					}}
					category="standard"
					name="sales_path"
					style={{
						backgroundColor: "#ea7600",
						fill: "#ffffff",
					}}
					title="Home"
				/>
			}
			onRenderActions={actions}
			title="Buyers"
			trail={["Interested Buyers"]}
			truncate
			variant="object-home"
		/>
	);
};
