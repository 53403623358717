import { Button } from "@salesforce/design-system-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import classes from "./Submitbuttons.module.scss";
import spinner from "@/features/pricing-worksheet/components/spinner.module.scss";
import { useFormReset } from "@/features/pricing-worksheet/hooks/useFormReset";
import { useFormSection } from "@/features/pricing-worksheet/hooks/useFormSection";

export const SubmitButtons = ({ form }: { form: string }) => {
	const {
		formState: { isSubmitting, isSubmitSuccessful, dirtyFields },
	} = useFormContext();

	const reset = useFormReset();
	const { isStatic } = useFormSection();

	const handleReset = () => {
		reset();
	};

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset();
		}
	}, [isSubmitSuccessful, reset]);

	return (
		(!isStatic || Object.keys(dirtyFields).length > 0) && (
			<div className={classes.container}>
				<div>
					<Button onClick={handleReset}>Cancel</Button>
					<Button
						variant="brand"
						type="submit"
						form={form}
						disabled={!Object.keys(dirtyFields).length || isSubmitting}
						{...(isSubmitting && {
							iconClassName: spinner.spinner,
							iconName: "spinner",
							iconPosition: "left",
							iconCategory: "utility",
						})}
					>
						Save
					</Button>
				</div>
			</div>
		)
	);
};
