import { DESCRIPTION } from "@/features/listing/components/constants";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Checkbox, Modal } from "@salesforce/design-system-react";
import { useState } from "react";

const ListingConfirmationModal = NiceModal.create(
	({ description }: { description: string }) => {
		const modal = useModal();
		const [isChecked, setIsChecked] = useState(false);

		const close = () => {
			modal.reject(new Error("closed modal"));
			modal.hide();
		};

		const save = () => {
			modal.resolve();
			modal.hide();
		};
		return (
			<Modal isOpen={modal.visible} size="large" onRequestClose={close}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						padding: "1rem",
						gap: "1rem",
					}}
				>
					<div style={{ width: "100%" }}>
						<h1 className="slds-text-heading_large">Save Listing to Web</h1>
					</div>
					<div
						style={{
							width: "100%",
							backgroundColor: "#F3F2F2",
							padding: "0.5rem",
						}}
					>
						<p style={{ fontWeight: "bold" }}>{DESCRIPTION.label}</p>
						<blockquote>{description}</blockquote>
					</div>
					<div>
						<Checkbox
							labels={{
								label:
									"I have read and approved of showing the above as the Listing Description on the website.",
							}}
							checked={isChecked}
							onChange={() => {
								setIsChecked(!isChecked);
							}}
						/>
					</div>
					<div
						style={{ display: "flex", justifyContent: "center", width: "100%" }}
					>
						<Button onClick={close}>Cancel</Button>
						<Button onClick={save} variant="brand" disabled={!isChecked}>
							Save
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
);

NiceModal.register("listing-confirmation-modal", ListingConfirmationModal);

export const useListingConfirmationModal = () =>
	useModal("listing-confirmation-modal");
