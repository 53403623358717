import {
	ACCOUNT_BALANCE,
	BASE_PRICE,
	CREDITS,
	LOT_RENT,
	TRANSPORT_TO_HOME_CENTER,
	APPLIANCES,
	HVAC,
	DELIVERY,
	MOWING,
	UTILITIES,
	SURVEY,
	OTHER_EXPENSES,
	COMMISSION_PERCENT,
	F_H_A_PREP,
	TOTAL_EXPENSES,
	NET_SALE,
	RECOVERY,
	APPROVAL_STATUS,
	SELECTED_PLAN,
	GENERAL_REFURB,
	DECK,
	FOOTERS,
	FRENCH_DRAIN,
	SEPTIC,
	SEPTIC_PERMIT,
	SEWER_LINE,
	GRAVEL,
	DRIVEWAY,
	LAND_CLEARING,
	WATER_LINE,
	WELL_PUMP,
	ELECTRIC,
	SALE_PRICE,
	CLOSING_COSTS_PERCENT,
	OTHER_REFURBISHING_EXEPENSES,
	OTHER_LAND_IMPROVEMENTS,
	TRASH_OUT,
	LAND_PURCHASE_PRICE,
	BUILDING_PERMIT,
	CLOSING_COSTS,
	COMMISSION,
} from "../constants";
import { create, each, enforce, test } from "vest";
import { get } from "lodash";

export const pricingWorksheetValidation = create((data = {}) => {
	each(
		[
			ACCOUNT_BALANCE,
			BASE_PRICE,
			CREDITS,
			LOT_RENT,
			TRANSPORT_TO_HOME_CENTER,
			DELIVERY,
			APPLIANCES,
			HVAC,
			MOWING,
			UTILITIES,
			TRASH_OUT,
			DECK,
			F_H_A_PREP,
			GENERAL_REFURB,
			OTHER_REFURBISHING_EXEPENSES,
			LAND_PURCHASE_PRICE,
			BUILDING_PERMIT,
			LAND_CLEARING,
			DRIVEWAY,
			GRAVEL,
			FOOTERS,
			FRENCH_DRAIN,
			SEPTIC,
			SEPTIC_PERMIT,
			SEWER_LINE,
			WATER_LINE,
			WELL_PUMP,
			ELECTRIC,
			OTHER_LAND_IMPROVEMENTS,
			OTHER_EXPENSES,
			SURVEY,
			CLOSING_COSTS_PERCENT,
			CLOSING_COSTS,
			COMMISSION_PERCENT,
			COMMISSION,
			TOTAL_EXPENSES,
			SALE_PRICE,
			NET_SALE,
			RECOVERY,
		],
		(field) => {
			test(`retailEstimates.${field.key}`, "Must be a positive number", () => {
				[RECOVERY.key, NET_SALE.key, TOTAL_EXPENSES.key].includes(field.key)
					? enforce(get(data, `retailEstimates.${field.key}`))
							.message("Must be a number")
							.isNumber()
					: enforce(get(data, `retailEstimates.${field.key}`))
							.isNumber()
							.gte(0);
			});

			test(
				`wholesaleEstimates.${field.key}`,
				"Must be a positive number",
				() => {
					[RECOVERY.key, NET_SALE.key, TOTAL_EXPENSES.key].includes(field.key)
						? enforce(get(data, `wholesaleEstimates.${field.key}`))
								.message("Must be a number")
								.isNumber()
						: enforce(get(data, `wholesaleEstimates.${field.key}`))
								.isNumber()
								.gte(0);
				}
			);

			test(`miscEstimates.${field.key}`, "Must be a positive number", () => {
				[RECOVERY.key, NET_SALE.key, TOTAL_EXPENSES.key].includes(field.key)
					? enforce(get(data, `miscEstimates.${field.key}`))
							.message("Must be a number")
							.isNumber()
					: enforce(get(data, `miscEstimates.${field.key}`))
							.isNumber()
							.gte(0);
			});
		}
	);

	test(SELECTED_PLAN.key, "You must select a plan", () => {
		if (get(data, APPROVAL_STATUS.key) === 2) {
			enforce(get(data, SELECTED_PLAN.key)).isNumber().isBetween(0, 4);
		}
		enforce(get(data, SELECTED_PLAN.key)).oneOf(
			enforce.isNumber().isBetween(0, 4),
			enforce.isNull()
		);
	});
});
