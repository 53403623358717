import { Button } from "@salesforce/design-system-react";
import { useController } from "react-hook-form";

export const FreeFormInput = ({
	name,
	label,
}: {
	name: string;
	label: string;
}) => {
	const { field, fieldState } = useController({ name });

	const resizeTextarea = (el: HTMLTextAreaElement) => {
		const outerHeight = parseInt(window.getComputedStyle(el).height, 10);
		const diff = outerHeight - el.clientHeight;

		el.style.height = "0px";
		el.style.height = Math.max(36, el.scrollHeight + diff) + "px";
	};

	return (
		<div
			style={{ display: "flex", flexDirection: "column", width: "100%" }}
			className={`slds-form-element ${
				fieldState.error ? "slds-has-error" : ""
			}`}
		>
			{(field.value ?? "")
				?.split(/(?<!\\),/)
				.map((value: string, index: number, arr: string[]) => {
					return (
						<div key={`${name}-input-${index}`}>
							<div style={{ width: "100%", height: "100%" }}>
								{index === 0 && (
									<>
										<label className="slds-form-element__label" htmlFor={name}>
											{label}
										</label>

										{fieldState.error && (
											<p className="slds-form-element__help" id="error-01">
												{fieldState.error.message}
											</p>
										)}
									</>
								)}
								<div style={{ display: "flex", marginBottom: "0.5rem" }}>
									<textarea
										className="slds-textarea"
										value={value.replaceAll("\\,", ",")}
										style={{
											width: "100%",
											height: "36px",
											overflowY: "auto",
										}}
										onChange={(e) => {
											const el = e.target as HTMLTextAreaElement;
											resizeTextarea(el);
											const newvalue = [
												...arr.slice(0, index),
												e.target.value.replace(/(?<!\\),/g, "\\,"),
												...arr.slice(index + 1),
											].join(",");
											field.onChange(newvalue);
										}}
									></textarea>

									<Button
										style={{ verticalALign: "middle", width: "32px" }}
										iconCategory="utility"
										iconName="delete"
										variant="icon"
										onClick={() => {
											const newvalue = [
												...arr.slice(0, index),
												...arr.slice(index + 1),
											].join(",");
											field.onChange(newvalue);
										}}
									></Button>
								</div>
							</div>
						</div>
					);
				})}
			<div style={{ alignSelf: "start" }}>
				<Button
					iconCategory="utility"
					iconName="add"
					variant="icon"
					onClick={() => field.onChange((field: string) => field + ",")}
				></Button>
			</div>
		</div>
	);
};
