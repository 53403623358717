export const HOME_INVOICE_PRICE = {
	key: "homeInvoicePrice",
	label: "Home Invoice Price",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const ORIGINAL_LAND_APPRAISAL = {
	key: "originalLandAppraisal",
	label: "Original Land Appraisal",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const ORIGINAL_SALES_PRICE = {
	key: "originalSalesPrice",
	label: "Original Sales Price",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const CASH_EQUITY = {
	key: "cashEquity",
	label: "Cash Equity",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const WEB_PRICE = {
	key: "webPrice",
	label: "Web Price",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const PURCHASE_OPTION = {
	key: "purchaseOption",
	label: "Purchase Option",
	props: {
		type: "enum",
		span: 4,
		section: "worksheet",
		options: ["Cash Only", "Financing Available", "Unknown"],
		history: false,
	},
};

export const RECOVERY_LAND_CREDIT = {
	key: "recoveryLandCredit",
	label: "Recovery Land Credit",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const HOME_VALUE = {
	key: "homeValue",
	label: "Home Value",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const LAND_VALUE = {
	key: "landValue",
	label: "Land Value",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const NADA_BASE = {
	key: "nadaBase",
	label: "NADA Base",
	props: {
		span: 4,
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};
