import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "@/providers/config/config";
import {
	getInsuranceQueue,
	requestInsuranceCancellation,
} from "@/features/insurance-cancellation-queue/api/InsuranceApi";
import { getAssetEndpoint } from "@/features/asset/api/getAssetEndpoint";
import { acquireAccessToken } from "@/auth";

export const getToken = async () => {
	return await acquireAccessToken();
};

export const getAPIURL = () => {
	return config?.REACT_APP_API_URL === "#{REACT_APP_API_URL}" ||
		config?.REACT_APP_API_URL === undefined
		? process.env.NODE_ENV === "test"
			? "http://localhost/"
			: "/"
		: config.REACT_APP_API_URL;
};

const rawBaseQuery = (baseUrl, token) =>
	fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => {
			headers.set("Authorization", `Bearer ${token}`);
			return headers;
		},
	});

export const dynamicBaseQuery = async (args, api, extraOptions) => {
	const baseUrl = getAPIURL();
	const token = await getToken();
	return rawBaseQuery(baseUrl, token)(args, api, extraOptions);
};

export const gatewayApi = createApi({
	reducerPath: "gatewayApi",
	baseQuery: dynamicBaseQuery,
	// Refetch on reconnect can be done per-endpoint rather than globally if necessary later
	// refetchOnReconnect: true,
	tagTypes: [
		"Account",
		"Asset",
		"AssetList",
		"InsuranceLoans",
		"UNAUTHORIZED",
		"UNKNOWN_ERROR",
		"Live",
		"OfflineUse",
	],
	endpoints: (builder) => ({
		getAccountById: builder.query({
			method: "GET",
			query: (id) => `api/Search/Account/${id}`,
			providesTags: (result, error, id) => {
				if (error) return concatErrorCache([{ type: "Account", id }], error);

				return [{ type: "Account", id }];
			},
		}),
		getAsset: getAssetEndpoint(builder),
		getInsuranceQueue: getInsuranceQueue(builder),
		requestInsuranceCancellation: requestInsuranceCancellation(builder),
		live: builder.query({
			query: () => ({
				url: "/health/live",
				method: "get",
			}),
			providesTags: () => ["Live"],
		}),
	}),
});

export const {
	useGetAssetQuery,
	useLazyGetAssetQuery,
	useGetAssetQueryState,
	useGetAccountByIdQuery,
	useGetInsuranceQueueQuery,
	useRequestInsuranceCancellationQuery,
	useRequestInsuranceCancellationMutation,
} = gatewayApi;

export const usePrefetch = gatewayApi.usePrefetch;

export function concatErrorCache(existingCache, error) {
	if (error && "status" in error && error.status === 401) {
		// unauthorized error
		return [...existingCache, "UNAUTHORIZED"];
	}
	// unknown error
	return [...existingCache, "UNKNOWN_ERROR"];
}
