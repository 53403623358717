import {
	COMMUNITY,
	FEATURES,
	GENERAL,
	INTERIOR,
	UPDATES,
	APPLIANCES,
	EXTERIOR,
	CONDITION,
	DESCRIPTION,
	MISCELLANEOUS,
	ASSET_MANAGER_NOTES,
	RBI_NUMBER,
} from "@/features/listing/components/constants";
import { get } from "lodash";
import { create, each, enforce, test } from "vest";

export const webFormValidation = create((data) => {
	each(
		[
			COMMUNITY.fields,
			FEATURES.fields,
			GENERAL.fields,
			INTERIOR.fields,
			UPDATES.fields,
			APPLIANCES.fields,
			EXTERIOR.fields,
			CONDITION.fields,
		].flat(),
		(field) => {
			test(field.path, () => {
				enforce(get(data, field.path)).isBoolean();
			});
		}
	);

	each(
		[DESCRIPTION, MISCELLANEOUS, ASSET_MANAGER_NOTES, RBI_NUMBER],
		(field) => {
			test(
				field.path,
				`${
					get(data, field.path).length - 2000
				} characters over the character limit`,
				() => {
					enforce(get(data, field.path)).isString().shorterThanOrEquals(2000);
				}
			);
		}
	);

	test(RBI_NUMBER.path, () => {
		enforce(get(data, RBI_NUMBER.path)).isString();
	});
});
