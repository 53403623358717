import React from "react";
import classes from "./DescriptionGenerator.module.scss";

export const PromptEditor = ({
	prompt,
	setPrompt,
}: {
	prompt: string;
	setPrompt: (prompt: string) => void;
}) => {
	return (
		<textarea
			className={classes.promptText}
			value={prompt}
			onChange={(e) => setPrompt(e.target.value)}
		/>
	);
};
