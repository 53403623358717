import React, { useState } from "react";
import classes from "./AssetHeader.module.scss";
import {
	Icon,
	PageHeader,
	Button,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { headerDetails } from "./headerDetails";
import { useSelectRemarketingStatuses } from "@/features/lookups";
import { useAssetHeader } from "./useAssetHeader";
import { Desktop, Mobile } from "@/Responsive";
import { OfflineAssetButton } from "@/features/offline-use/components/OfflineAssetButton/OfflineAssetButton";
import { CameraButton } from "../Camera/CameraButton";
import { get } from "lodash";
import { ACCOUNT_NUMBER, ADDRESS } from "../../constants";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { TASK_TRACKER_FEATURE_FLAG } from "@/providers/feature-flags/constants";
import { useGetSmartsheetLink } from "@/features/smartsheet/useGetSmartsheetLink";
//This header is for the asset page with URL /account/id/asset/id
export const AssetHeader = ({ assetId }) => {
	const { asset, isSuccess } = useAssetHeader(assetId);
	// Get the list of statuses from the lookup
	const statuses = useSelectRemarketingStatuses();
	const [showDetails, setShowDetails] = useState(false);
	const chevron = showDetails ? "chevronup" : "chevrondown";

	const isEnabledTaskTracker = useFeature(TASK_TRACKER_FEATURE_FLAG);

	const smartSheetLink = useGetSmartsheetLink(
		get(asset, ACCOUNT_NUMBER.path)?.toString()
	);

	// Action bar for Asset Header
	const actions = () => (
		<>
			<PageHeaderControl>
				<Mobile>
					<OfflineAssetButton
						accountId={asset?.accountId}
						assetId={asset?.id}
						variant="header"
					/>
					<CameraButton />
				</Mobile>
				{isEnabledTaskTracker && (
					<>
						<Mobile>
							<a
								className="slds-button slds-button_brand slds-button_icon-border-filled"
								href={smartSheetLink}
								target="blank"
							>
								<Icon
									title={`Create Task`}
									name="task"
									size="small"
									style={{
										width: "16px",
										height: "16px",
									}}
									category="utility"
								/>
							</a>
						</Mobile>
						<Desktop>
							<a
								className="slds-button slds-button_brand"
								href={smartSheetLink}
								target="blank"
							>
								Create Task
							</a>
						</Desktop>
					</>
				)}

				<Button
					iconCategory="utility"
					iconName={chevron}
					iconSize="small"
					iconVariant="bare"
					variant="icon"
					onClick={() => setShowDetails(!showDetails)}
				/>
			</PageHeaderControl>
		</>
	);

	return (
		<>
			{isSuccess && (
				<div className={classes.assetHeader}>
					<PageHeader
						details={headerDetails(asset, statuses)}
						icon={
							<Icon
								assistiveText={{ label: "Home" }}
								category="standard"
								name="home"
								style={{
									backgroundColor: "rgb(13, 105, 13)",
									fill: "#ffffff",
								}}
							/>
						}
						onRenderActions={actions}
						label={get(asset, ADDRESS.path)}
						title={get(asset, ACCOUNT_NUMBER.path)?.toString() || ""}
						variant={showDetails ? "record-home" : "object-home"}
					/>
				</div>
			)}
		</>
	);
};

export default AssetHeader;
