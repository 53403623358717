import { PricingWorksheetType } from "../types";
import { vestResolver } from "@hookform/resolvers/vest";
import {
	Button,
	DataTable,
	DataTableCell,
	DataTableColumn,
} from "@salesforce/design-system-react";
import { createContext, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
	ACCOUNT_BALANCE,
	APPLIANCES,
	BASE_PRICE,
	BUILDING_PERMIT,
	CLOSING_COSTS,
	COMMENTS,
	COMMISSION,
	CREDITS,
	DECK,
	DELIVERY,
	DRIVEWAY,
	ELECTRIC,
	FOOTERS,
	FRENCH_DRAIN,
	F_H_A_PREP,
	GENERAL_REFURB,
	GRAVEL,
	HVAC,
	LAND_CLEARING,
	LAND_IMPROVEMENTS,
	LAND_PURCHASE_PRICE,
	LOT_RENT,
	MOWING,
	NET_SALE,
	OTHER_EXPENSES,
	OTHER_LAND_IMPROVEMENTS,
	OTHER_REFURBISHING_EXEPENSES,
	RECOVERY,
	REFURBISHING,
	SALE_PRICE,
	SELECTED_PLAN,
	SELECTED_PLAN_OPTIONS,
	SEPTIC,
	SEPTIC_PERMIT,
	SEWER_LINE,
	SURVEY,
	TOTAL_EXPENSES,
	TRANSPORT_TO_HOME_CENTER,
	TRASH_OUT,
	UTILITIES,
	WATER_LINE,
	WELL_PUMP,
} from "../constants";
import styles from "../PricingWorksheet.module.scss";
import { FormFocusProvider } from "../providers/FormFocusContext";
import { FormResetProvider } from "../providers/FormResetProvider";
import { FormSectionProvider } from "../providers/FormSectionProvider";
import { ApprovalButton } from "./ApprovalButton";
import { CategoryCell } from "./CategoryCell";
import { pricingWorksheetValidation } from "./pricingWorksheetValidation";
import { SubmitButtons } from "./SubmitButtons";
import { WorksheetCell } from "./WorksheetCell";
import { WorksheetComment } from "./WorksheetComment";
import { WorksheetHeader } from "./WorksheetHeader";
import { WorksheetInput } from "./WorksheetInput";
import { usePricingWorksheet } from "../usePricingWorksheet";
import { ActualsCell, VarianceCell } from "./ActualsCell";
import { WorksheetTitle } from "./WorksheetTitle";
import { usePricingWorksheetHistory } from "../hooks/usePricingWorksheetHistory";
import { CreateWorksheetButton } from "./CreateWorksheetButton";

const categories = {
	ACCOUNT_BALANCE,
	BASE_PRICE,
	CREDITS,
	LOT_RENT,
	TRANSPORT_TO_HOME_CENTER,
	DELIVERY,
	REFURBISHING,
	APPLIANCES,
	HVAC,
	MOWING,
	UTILITIES,
	TRASH_OUT,
	DECK,
	F_H_A_PREP,
	GENERAL_REFURB,
	OTHER_REFURBISHING_EXEPENSES,
	LAND_IMPROVEMENTS,
	LAND_PURCHASE_PRICE,
	BUILDING_PERMIT,
	LAND_CLEARING,
	DRIVEWAY,
	GRAVEL,
	FOOTERS,
	FRENCH_DRAIN,
	SEPTIC,
	SEPTIC_PERMIT,
	SEWER_LINE,
	WATER_LINE,
	WELL_PUMP,
	ELECTRIC,
	OTHER_LAND_IMPROVEMENTS,
	OTHER_EXPENSES,
	SURVEY,
	CLOSING_COSTS,
	COMMISSION,
	TOTAL_EXPENSES,
	SALE_PRICE,
	NET_SALE,
	RECOVERY,
};

export const subCategories = {
	[REFURBISHING.key]: [
		F_H_A_PREP.key,
		HVAC.key,
		APPLIANCES.key,
		MOWING.key,
		UTILITIES.key,
		GENERAL_REFURB.key,
		TRASH_OUT.key,
		DECK.key,
		OTHER_REFURBISHING_EXEPENSES.key,
	],
	[LAND_IMPROVEMENTS.key]: [
		LAND_PURCHASE_PRICE.key,
		SEPTIC.key,
		SEPTIC_PERMIT.key,
		SEWER_LINE.key,
		WATER_LINE.key,
		WELL_PUMP.key,
		BUILDING_PERMIT.key,
		LAND_CLEARING.key,
		DRIVEWAY.key,
		GRAVEL.key,
		FOOTERS.key,
		FRENCH_DRAIN.key,
		ELECTRIC.key,
		OTHER_LAND_IMPROVEMENTS.key,
	],
};

export interface PricingWorksheetColumns {
	id: string;
	category: string;
	retail: string;
	wholesale: string;
	miscellaneous: string;
	actual: string;
	retailVar: string;
}

const createColumns = (): PricingWorksheetColumns[] =>
	Object.values(categories).map((field) => ({
		id: field.key,
		category: field.label,
		retail: `retailEstimates.${field.key}`,
		wholesale: `wholesaleEstimates.${field.key}`,
		miscellaneous: `miscEstimates.${field.key}`,
		actual: field.key,
		retailVar: field.key,
	}));

interface SubcategoryContextType {
	subCategories: string[];
	isOpen: (category: string) => boolean;
	toggleOpen: (key: string) => void;
}

const SubcategoryContext = createContext({} as SubcategoryContextType);

export const useSubcategory = () => useContext(SubcategoryContext);

interface PricingWorksheetFormProps {
	data: PricingWorksheetType;
	onSubmit: (data: PricingWorksheetType) => void;
	title?: string;
	isOpen?: boolean;
	onToggleOpen?: () => void;
	isFetching: boolean;
	homeId: string;
}

export const PricingWorksheetForm = ({
	data,
	onSubmit,
	title,
	isOpen: formIsOpen,
	onToggleOpen,
	isFetching,
	...props
}: PricingWorksheetFormProps) => {
	const methods = useForm<PricingWorksheetType>({
		defaultValues: data,
		resolver: vestResolver(pricingWorksheetValidation),
	});

	useEffect(() => {
		if (!methods?.formState?.isSubmitting && !isFetching) {
			methods.reset(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isFetching]);

	const [isOpenSubtotal, setIsOpenSubtotal] = useState({
		[REFURBISHING.key]: false,
		[LAND_IMPROVEMENTS.key]: false,
	});

	const {
		isHomeRepossessed,
		actuals,
		currentIndex,
		goToCurrentWorksheet,
		approvalStatus,
	} = usePricingWorksheet(props.homeId);

	const inProgress =
		data.approvalStatus < 2 ||
		(data.approvalStatus === 2 && !isHomeRepossessed);

	const [isOpenHistory, setIsOpenHistory] = usePricingWorksheetHistory();
	return (
		<FormProvider {...methods}>
			<FormResetProvider>
				<FormFocusProvider>
					<FormSectionProvider
						isEditable={
							inProgress && !methods.formState.isSubmitting && !isFetching
						}
						isFetching={isFetching}
						onActivateSection={({ section, setSections }) => {
							setSections((sections) => ({
								...Object.fromEntries(
									Object.entries(sections).map(([key, value]) => {
										if (key === "worksheet") {
											return [key, value];
										}
										if (key === "comment" && section.match(/estimate/i)) {
											return [key, true];
										}
										if (
											[
												"retailEstimates",
												"miscEstimates",
												"wholesaleEstimates",
											].includes(key) ||
											[
												"retailEstimates",
												"miscEstimates",
												"wholesaleEstimates",
											].includes(section)
										) {
											return [key, true];
										}

										return [key, false];
									})
								),
								[section]: true,
							}));
						}}
					>
						<SubcategoryContext.Provider
							value={{
								subCategories: Object.keys(isOpenSubtotal),
								isOpen: (category) => isOpenSubtotal[category],
								toggleOpen: (key) => {
									setIsOpenSubtotal((state) => ({
										...state,
										[key]: !state[key],
									}));
								},
							}}
						>
							<form
								onSubmit={methods.handleSubmit(onSubmit)}
								id={`pw-${props.homeId}`}
							>
								<div className={styles.statusLinksContainer}>
									<WorksheetInput
										key={SELECTED_PLAN.key}
										name={SELECTED_PLAN.key}
										label={SELECTED_PLAN.label}
										type="enum"
										options={SELECTED_PLAN_OPTIONS}
										section="worksheet"
									/>
									<div>
										<ApprovalButton assetId={props.homeId} />

										{approvalStatus === "Approved" && currentIndex === 0 && (
											<CreateWorksheetButton assetId={props.homeId} />
										)}
									</div>
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "0 1rem ",
										paddingTop: 0,
										marginTop: 0,
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "flex-end",
											gap: "1rem",
										}}
									>
										{currentIndex !== 0 && (
											<Button
												variant="base"
												onClick={goToCurrentWorksheet}
												label={"Go to Current Worksheet"}
												iconName={"chevronleft"}
												iconCategory="utility"
												iconPosition="left"
											/>
										)}
										<Button
											variant="base"
											onClick={setIsOpenHistory}
											label={isOpenHistory ? "Close" : "History"}
											iconName={isOpenHistory ? "close" : "skip_back"}
											iconCategory="utility"
											iconPosition="left"
										/>
									</div>
								</div>
								<div>
									<WorksheetTitle
										homeId={props.homeId}
										data={data}
										index={currentIndex}
									/>
									<div className={styles.worksheetTable}>
										<>
											<DataTable
												isHeadless
												striped
												items={[
													{
														id: "headers",
														key: "headers",
														type: "header-row",
														category: "Category",
														retail: `Est. Retail`,
														wholesale: `Est. Wholesale`,
														miscellaneous: `Miscellaneous`,
														actual: "Actual",
														retailVar: "Retail Var",
													},
													{
														id: "stripe-fix",
														type: "header-row",
													},
													...createColumns().filter(({ id }) => {
														for (const [key, value] of Object.entries(
															subCategories
														)) {
															if (value.includes(id)) {
																return isOpenSubtotal[key];
															}
														}
														return true;
													}),
												]}
												id="pw-table"
												key={`pw-${data.approvedDate ?? "in-progress"}`}
												isSorted={false}
												noRowHover
												columnBordered
												onRenderSubHeadingRow={WorksheetHeader}
											>
												<DataTableColumn
													key="category"
													label="Category"
													property="category"
													width="90px"
												>
													<CategoryCell key="category" />
												</DataTableColumn>
												<DataTableColumn
													key="retail"
													label="Est. Retail"
													property="retail"
												>
													<WorksheetCell data={data} />
												</DataTableColumn>
												<DataTableColumn
													key="wholesale"
													label="Est. Wholesale"
													property="wholesale"
												>
													<WorksheetCell data={data} />
												</DataTableColumn>
												<DataTableColumn
													key="miscellaneous"
													label="Miscellaneous"
													property="miscellaneous"
												>
													<WorksheetCell data={data} />
												</DataTableColumn>
												<DataTableColumn
													key="actual"
													label="Actual"
													property="actual"
												>
													<ActualsCell data={actuals} />
												</DataTableColumn>
												<DataTableColumn
													key="retailVar"
													label="Retail Var"
													property="retailVar"
												>
													<VarianceCell data={actuals} />
												</DataTableColumn>
											</DataTable>
											<WorksheetComment name={COMMENTS.key} />
										</>
									</div>
								</div>
								<SubmitButtons form={`pw-${props.homeId}`} />
							</form>
						</SubcategoryContext.Provider>
					</FormSectionProvider>
				</FormFocusProvider>
			</FormResetProvider>
		</FormProvider>
	);
};

CategoryCell.displayName = DataTableCell.displayName;
WorksheetCell.displayName = DataTableCell.displayName;
ActualsCell.displayName = DataTableCell.displayName;
VarianceCell.displayName = DataTableCell.displayName;
