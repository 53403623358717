import React, { useMemo, useState } from "react";
import { AssetHeader } from "@/features/asset/components/asset-page-header/AssetHeader";
import classes from "./AssetContainer.module.scss";
import { AssetMediaManager } from "@/features/media-manager/AssetMediaManager";
import { Tabs, TabsPanel } from "@salesforce/design-system-react";
import { ErrorContainer } from "../errors/ErrorContainer";
import { AssetDetailTab } from "../asset-details-tab/AssetDetailTab";
import splitViewClass from "@/features/media-manager/components/media-container/MediaContainer.module.scss";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import {
	useGetLoanTypesQuery,
	useGetMediaTypesQuery,
	useGetRemarketingStatusesQuery,
} from "@/features/lookups";
import { AssetIdProvider } from "./components/AssetIdProvider";
import { useTabs } from "@/hooks/useTabs";
import { ConditionReport } from "../condition-report/ConditionReport";
import { Documentation } from "../documentation/Documentation";
import { useAssetListSplitView } from "../asset-list/hooks/useAssetListSplitView";
import { useParams } from "react-router-dom";
import { useSplitViewAsset } from "../asset-list/hooks/useSplitViewAsset";
import { MdPending } from "react-icons/md";
import { usePendingSyncItems } from "../offline-use/api/hooks/usePendingSyncItems";
import { Booking } from "../booking/Booking";
import { Imaging } from "../imaging/Imaging";
import { useIsMobile } from "@/Responsive";
import { PricingWorksheet } from "../pricing-worksheet/PricingWorksheet";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { Web } from "@/features/web/Web";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";

export const AssetContainer = ({ container, ...props }) => {
	const { isSplitView } = useAssetListSplitView();
	// lookup query for the statuses
	const { isLoading: isFetchingStatuses, isError: isErrorStatuses } =
		useGetRemarketingStatusesQuery();
	const { isLoading: isFetchingLoanTypes, isError: isErrorLoanTypes } =
		useGetLoanTypesQuery();
	// lookup query for mediaTypes
	const { isLoading: isFetchingMediaTypes } = useGetMediaTypesQuery();

	const { accountId, assetId: assetPageAssetId } = useParams();
	const { assetId: splitViewAssetId } = useSplitViewAsset();

	const assetId = useMemo(
		() => assetPageAssetId || splitViewAssetId,
		[assetPageAssetId, splitViewAssetId]
	);
	// Get Asset by ID query
	const {
		data: asset,
		error,
		isError: isErrorAsset,
		isLoading: isLoadingAsset,
	} = useGetAssetQuery(assetId);

	// Combine the is fetching data
	const isFetching =
		isLoadingAsset ||
		isFetchingStatuses ||
		isFetchingLoanTypes ||
		isFetchingMediaTypes;

	const isError = isErrorStatuses || isErrorAsset || isErrorLoanTypes;

	return (
		<AssetIdProvider accountId={accountId} assetId={assetId}>
			{isError && <ErrorContainer error={error} />}
			{isFetching && <Spinner />}
			{isFetching === false && asset && (
				<div className={isSplitView ? splitViewClass.splitView : undefined}>
					<div className={classes.assetHeader}>
						<AssetHeader assetId={assetId} />
					</div>
					<AssetTabs
						asset={asset}
						isFetching={isFetching}
						container={container}
					/>
				</div>
			)}
		</AssetIdProvider>
	);
};

function TabLabel({ isActive, isPending = false, children }) {
	return (
		<span>
			{isPending && (
				<span
					style={{
						top: "-.75rem",
						left: "0",
						position: "absolute",
						color: isActive ? "#005fb2" : "#c9c7c5",
					}}
				>
					<MdPending size={"1rem"} />
				</span>
			)}
			{children}
		</span>
	);
}

const AssetTabs = ({ asset, isFetching, container }) => {
	const { currentTab, setCurrentTab } = useTabs();

	const localStorageSideView =
		JSON.parse(localStorage.getItem("sideViewModules")) ?? [];

	const [sideModules, setSideModules] = useState(localStorageSideView);
	const toggleSideModule = (value) => {
		const newSideModules = sideModules.includes(value)
			? [...sideModules.filter((item) => item !== value)]
			: [...sideModules, value];
		localStorage.setItem("sideViewModules", JSON.stringify(newSideModules));
		setSideModules(newSideModules);
	};
	const { isSplitView } = useAssetListSplitView();
	const isMobile = useIsMobile();
	const { assetDetails, mediaUploads, conditionReport, documentation } =
		usePendingSyncItems(asset.id);

	const isEnabledPricingWorksheet = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);

	// imaging is full screen if currentTab === "imaging"
	if (!isMobile && !isSplitView && currentTab === "imaging") {
		return (
			<Imaging close={() => {}} accountNumber={asset.account.accountNumber} />
		);
	}
	// notes is full screen if currentTab === "notes"
	if (!isMobile && !isSplitView && currentTab === "notes") {
		return <Documentation />;
	}

	const tabsArray = [
		"details",
		"media",
		"conditionReport",
		isEnabledPricingWorksheet && "marketingPlan",
		"booking",
		"web",
		"notes",
		"imaging",
	].filter((tab) => tab);

	let containerClass = isSplitView
		? classes.splitViewContainer
		: sideModules.length > 0
		? classes.assetGridContainer
		: classes.containerWithSideView;
	return (
		<div className={containerClass}>
			<Tabs
				onSelect={(index) => setCurrentTab(tabsArray[index])}
				selectedIndex={tabsArray.findIndex((element) => element === currentTab)}
				defaultSelectedIndex={0}
				variant="scoped"
				className={classes.tabsContainer}
				id="tabs-example-default"
			>
				<TabsPanel
					label={
						<TabLabel
							isActive={currentTab === "details"}
							data="details"
							isPending={assetDetails}
						>
							Details
						</TabLabel>
					}
				>
					{currentTab === "details" && (
						<AssetDetailTab
							isFetching={isFetching}
							asset={asset}
							account={asset.account}
						/>
					)}
				</TabsPanel>
				<TabsPanel
					label={
						<TabLabel
							isActive={currentTab === "media"}
							data="media"
							isPending={mediaUploads}
						>
							Media
						</TabLabel>
					}
				>
					{currentTab === "media" && (
						<AssetMediaManager container={container} />
					)}
				</TabsPanel>
				<TabsPanel
					label={
						<TabLabel
							isActive={currentTab === "conditionReport"}
							data="conditionReport"
							isPending={conditionReport}
						>
							Condition
						</TabLabel>
					}
				>
					{currentTab === "conditionReport" && <ConditionReport />}
				</TabsPanel>
				{isEnabledPricingWorksheet && (
					<TabsPanel
						label={
							<TabLabel
								isActive={currentTab === "marketingPlan"}
								data="marketingPlan"
							>
								Marketing Plan
							</TabLabel>
						}
					>
						{currentTab === "marketingPlan" && (
							<PricingWorksheet assetId={asset.id} />
						)}
					</TabsPanel>
				)}
				<TabsPanel
					label={
						<TabLabel isActive={currentTab === "booking"} data="booking">
							Booking
						</TabLabel>
					}
				>
					{currentTab === "booking" && <Booking assetId={asset.id} />}
				</TabsPanel>
				<TabsPanel
					label={
						<TabLabel isActive={currentTab === "web"} data="web">
							Web
						</TabLabel>
					}
				>
					{currentTab === "web" && <Web asset={asset} />}
				</TabsPanel>
				{(isMobile || isSplitView) && (
					<TabsPanel
						label={
							<TabLabel
								isActive={currentTab === "notes"}
								data="notes"
								isPending={documentation}
							>
								Notes
							</TabLabel>
						}
					>
						{currentTab === "notes" && <Documentation />}
					</TabsPanel>
				)}
				{(isMobile || isSplitView) && (
					<TabsPanel
						label={
							<TabLabel isActive={currentTab === "imaging"} data="imaging">
								Imaging
							</TabLabel>
						}
					>
						{currentTab === "imaging" && (
							<Imaging accountNumber={asset.account.accountNumber} />
						)}
					</TabsPanel>
				)}
			</Tabs>
			{!isMobile && !isSplitView && (
				<div
					style={{
						display: "grid",
						gridTemplateColumns:
							sideModules.length !== 0 && sideModules.length < 2
								? "1fr 20px"
								: "1fr",
					}}
				>
					{sideModules.length > 0 && (
						<div style={{ display: "flex", flexDirection: "column" }}>
							{sideModules.includes("imaging") && (
								<Imaging
									close={() => toggleSideModule("imaging")}
									accountNumber={asset.account.accountNumber}
								/>
							)}
							{sideModules.includes("notes") && (
								<Documentation close={() => toggleSideModule("notes")} />
							)}
						</div>
					)}
					{sideModules.length < 2 && (
						<div>
							<ul className={classes.rotateList}>
								{!sideModules.includes("imaging") && (
									<li
										onClick={() => toggleSideModule("imaging")}
										role="button"
										className={classes.rotateListItem}
									>
										<span className={classes.rotateInnerContent}>Imaging</span>
									</li>
								)}
								{!sideModules.includes("notes") && (
									<li
										onClick={() => toggleSideModule("notes")}
										role="button"
										className={classes.rotateListItem}
									>
										<span className={classes.rotateInnerContent}>notes</span>
									</li>
								)}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
