import { useEffect } from "react";
import styles from "../PricingWorksheet.module.scss";
import {
	CASH_EQUITY,
	HOME_INVOICE_PRICE,
	HOME_VALUE,
	LAND_VALUE,
	NADA_BASE,
	ORIGINAL_LAND_APPRAISAL,
	ORIGINAL_SALES_PRICE,
	WEB_PRICE,
	PURCHASE_OPTION,
	RECOVERY_LAND_CREDIT,
} from "../constants";
import { HoverHistoryTooltip } from "./HoverHistoryTooltip";
import { WorksheetInput } from "./WorksheetInput";
import { FormProvider, useForm } from "react-hook-form";
import { useLazyGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { vestResolver } from "@hookform/resolvers/vest";
import { pricingInfoValidation } from "./pricingInfoValidation";
import { FormResetProvider } from "../providers/FormResetProvider";
import { FormFocusProvider } from "../providers/FormFocusContext";
import { FormSectionProvider } from "../providers/FormSectionProvider";
import { usePatchAssetMutation } from "@/features/asset-details-tab/api/endpoints";
import { SubmitButtons } from "./SubmitButtons";
import { ExpandableSection } from "@salesforce/design-system-react";
import { Asset } from "@/features/asset/types";

export const formFields = {
	HOME_INVOICE_PRICE,
	ORIGINAL_LAND_APPRAISAL,
	ORIGINAL_SALES_PRICE,
	CASH_EQUITY,
	WEB_PRICE,
	PURCHASE_OPTION,
	RECOVERY_LAND_CREDIT,
	HOME_VALUE,
	LAND_VALUE,
	NADA_BASE,
};

export type PricingInfoHistory = {
	propertyPath: string;
	user: string;
	modifiedDate: string;
	value: number;
};

export interface PricingInfoType {
	homeInvoicePrice: number;
	originalLandAppraisal: number;
	originalSalesPrice: number;
	cashEquity: number;
	webPrice: number;
	purchaseOption: number;
	recoveryLandCredit: number;
	homeValue: number;
	landValue: number;
	nadaBase: number;
	pricingInfoHistory: PricingInfoHistory[];
	concurrencyToken: string;
}

const defaultPricingInfo: PricingInfoType = {
	homeInvoicePrice: 0,
	originalLandAppraisal: 0,
	originalSalesPrice: 0,
	cashEquity: 0,
	webPrice: 0,
	purchaseOption: 0,
	recoveryLandCredit: 0,
	homeValue: 0,
	landValue: 0,
	nadaBase: 0,
	pricingInfoHistory: [],
	concurrencyToken: "",
};

export const PricingInfoForm = ({ homeId }: { homeId: string }) => {
	const selectData = (data: Asset | undefined) => {
		return data?.marketingInfo?.pricingInfo ?? defaultPricingInfo;
	};

	const [fetchAsset, { data, isFetching, isLoading }] = useLazyGetAssetQuery({
		selectFromResult: ({ data, ...rest }) => {
			return {
				data: selectData(data),
				...rest,
			};
		},
	});

	const methods = useForm<PricingInfoType>({
		defaultValues: async (): Promise<PricingInfoType> => {
			const { data } = await fetchAsset(homeId);
			return selectData(data);
		},
		shouldUseNativeValidation: false,
		resolver: vestResolver(pricingInfoValidation),
	});

	const [patchAsset, { isFetching: isPatching }] = usePatchAssetMutation();

	useEffect(() => {
		methods.reset(data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFetching, isLoading, isPatching]);

	const onSubmit = async (data: PricingInfoType): Promise<void> => {
		await patchAsset({ id: homeId, marketingInfo: { pricingInfo: data } });
	};

	return (
		<ExpandableSection title="Pricing">
			<FormProvider {...methods}>
				<FormResetProvider>
					<FormFocusProvider>
						<FormSectionProvider
							isEditable={!methods.formState.isSubmitting && !isFetching}
							isFetching={isFetching}
						>
							<form
								onSubmit={methods.handleSubmit(onSubmit)}
								id={`pricing-info-form-${homeId}`}
							>
								<div className={styles.worksheetFieldContainer}>
									{Object.values(formFields).map((field) => (
										<WorksheetInput
											key={field.key}
											name={field.key}
											label={field.label}
											{...field.props}
											fieldLevelHelpTooltip={
												field.props?.history &&
												HoverHistoryTooltip({
													assetId: homeId,
													name: field.key,
													label: field.label,
												})
											}
										/>
									))}
								</div>
								<SubmitButtons form={`pricing-info-form-${homeId}`} />
							</form>
						</FormSectionProvider>
					</FormFocusProvider>
				</FormResetProvider>
			</FormProvider>
		</ExpandableSection>
	);
};
