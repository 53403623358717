import { Control, UseFormReset, UseFormWatch } from "react-hook-form";
import { BuyerFormType } from "./NewBuyer";
import { Button } from "@salesforce/design-system-react";
import {
	State,
	Input,
	DateInput,
	DriversLicenseNumber,
	Checkbox,
	IdentificationTypesSelect,
	PhoneNumberInput,
	SocialSecurityInput,
} from "./BuyerFormComponents";
import classes from "./BuyerForm.module.scss";

export const BuyerForm = ({
	control,
	watch,
	reset,
	isSubmitting,
}: {
	control: Control<BuyerFormType, any, BuyerFormType>;
	watch: UseFormWatch<BuyerFormType>;
	reset: UseFormReset<BuyerFormType>;
	isSubmitting: boolean;
}) => {
	return (
		<>
			<div className={classes.formContainer}>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<p>
						<span style={{ color: "red" }}>* </span>= required field
					</p>
				</div>
				<div>
					<p style={{ fontWeight: "bolder" }}>Personal Information</p>
					<div className={classes.formGrid}>
						<Input
							control={control}
							label="First Name"
							name="firstName"
							placeholder="John"
						/>
						<Input
							name="middleName"
							control={control}
							label="Middle Name"
							placeholder="Mark"
						/>
						<Input
							name="lastName"
							control={control}
							label="Last Name"
							placeholder="Doe"
						/>
						<Input
							control={control}
							name="institutionName"
							label="Institution Name"
							placeholder="Institution"
						/>
						<PhoneNumberInput
							control={control}
							name="phoneNumber1"
							required
							placeholder="Enter Phone #"
							type="tel"
							label="Phone Number 1"
						/>
						<PhoneNumberInput
							control={control}
							name="phoneNumber2"
							label="Phone Number 2"
							type="tel"
							placeholder="Enter Phone #"
						/>
						<Input
							control={control}
							name="emailAddress"
							label="Email Address"
							type="email"
							placeholder="john.doe@claytonhomes.com"
						/>
						<SocialSecurityInput
							label="SSEIN"
							control={control}
							name="ssein"
							placeholder="123-45-6789"
							type={"text"}
						/>
					</div>
				</div>
				<div style={{ paddingTop: ".5rem" }}>
					<p style={{ fontWeight: "bolder" }}>Buyer Location</p>
					<div className={classes.formGrid}>
						<Input
							control={control}
							name="address1"
							className={classes.spanTwo}
							required
							label="Address 1"
							placeholder="123 Main Street"
						/>
						<Input
							label="Address 2"
							control={control}
							name="address2"
							placeholder="Unit 2"
						/>
						<Input
							required
							label="City"
							control={control}
							name="city"
							placeholder="Maryville"
						/>
						<Input
							required
							label="County"
							control={control}
							name="county"
							placeholder="Blount"
						/>
						<State required label="State" control={control} name="state" />
						<Input
							required
							label="Zip"
							control={control}
							name="zip"
							placeholder="37801"
						/>
					</div>
				</div>
				<div style={{ paddingTop: ".5rem" }}>
					<p style={{ fontWeight: "bolder" }}>Drivers License Info</p>
					<div className={classes.formGrid}>
						<State
							label="State"
							name="driversLicense.state"
							control={control}
						/>
						<DateInput
							name={"driversLicense.expiration"}
							control={control}
							label="Expiration"
							placeholder={"MM/DD/YYYY"}
						/>
						<DriversLicenseNumber watch={watch} control={control} />
					</div>
				</div>
				<div style={{ paddingTop: ".5rem" }}>
					<p style={{ fontWeight: "bolder" }}>Miscellaneous</p>
					<div className={classes.formGrid}>
						<IdentificationTypesSelect control={control} />
						<Input
							label="Dealer License Number"
							control={control}
							name="dealerLicenseNumber"
							placeholder="Dealer Number"
						/>
						<DateInput
							label="OFAC Pull Date"
							control={control}
							name="ofacPullDate"
							placeholder="MM/DD/YYYY"
						/>
						<Checkbox
							name="isIdentificationImaged"
							control={control}
							label="Identification Imaged"
						/>

						<Checkbox name="isActive" control={control} label="Active" />
						<Checkbox
							name="isLienHolder"
							control={control}
							label="Lienholder"
						/>
						<Checkbox name="isInCareOf" control={control} label="In Care Of" />
					</div>
				</div>
			</div>
			<div className={classes.submitButtons}>
				<Button type={"reset"} onClick={reset}>
					Cancel
				</Button>
				<Button
					disabled={isSubmitting}
					type="submit"
					{...(isSubmitting && {
						iconClassName: classes.spinner,
						iconName: "spinner",
						iconPosition: "left",
						iconCategory: "utility",
					})}
					variant="brand"
				>
					Submit
				</Button>
			</div>
		</>
	);
};
