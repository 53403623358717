import React, { useState } from "react";
import {
	useBuyersSearchQuery,
	useGetSaleByIdQuery,
	useUpdateSaleMutation,
} from "../../api/endpoints";
import {
	Button,
	DataTable,
	DataTableCell,
	DataTableColumn,
	Input,
	InputIcon,
} from "@salesforce/design-system-react";
import { Buyer, BuyerSearchPayload } from "../../types";
import { BuyerCard } from "./BuyerCards";
import classes from "./BuyerSearch.module.scss";
import { BiCheck, BiPlus } from "react-icons/bi";
import { Desktop, Mobile } from "@/Responsive";
import { buyerColumnConfig } from "./BuyerTable";
import { useSaleId } from "../SaleContext";
import { FaArrowLeft, FaArrowRight, FaSpinner } from "react-icons/fa";

export const BuyerSearch = ({
	addBuyer,
}: {
	addBuyer: (buyer: Buyer) => void;
}) => {
	const [buyerSelected, setBuyerSelected] = useState<Buyer>();
	const [inputSearch, setInputSearch] = useState<string>("");
	const [buyerSearchQuery, setBuyerSearchQuery] = useState<BuyerSearchPayload>({
		address: "",
		firstName: "",
		institutionName: "",
		lastName: "",
		pageNumber: 0,
		pageSize: 15,
		phoneNumber: "",
	});

	const { data, isSuccess, isError, isFetching } =
		useBuyersSearchQuery(buyerSearchQuery);
	const numberOfPages =
		data && "totalItems" in data
			? Math.round(Math.ceil(data?.totalItems / buyerSearchQuery.pageSize))
			: 0;

	return (
		<div style={{ minHeight: "30vh", padding: "0.5rem", position: "relative" }}>
			<Input
				value={inputSearch}
				onChange={(e: any, { value }: { value: string }) => {
					setInputSearch(value);
					setBuyerSearchQuery(createSearchQuery(value));
				}}
				iconLeft={
					<InputIcon
						assistiveText={{
							icon: "Search",
						}}
						name="search"
						category="utility"
					/>
				}
				id="unique-id-1"
				label="Search for Buyers"
				placeholder="First Name, Last Name, Institution, Address, or Phone Number"
			/>
			{isError && (
				<p style={{ color: "red" }}>
					Something went wrong searching for buyers
				</p>
			)}
			{isFetching && (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "2rem",
					}}
				>
					<FaSpinner
						style={{
							height: 40,
							width: 40,
							fill: "grey",
						}}
						className={classes.spinner}
					/>
				</div>
			)}
			{isSuccess && !isFetching && (
				<>
					<Mobile>
						<div className={classes.searchResultContainer}>
							{data.items.map((item) => (
								<div
									key={item.id}
									className={classes.selected}
									onClick={() => {
										if (buyerSelected?.id === item.id) {
											setBuyerSelected(undefined);
										} else {
											setBuyerSelected(item);
										}
									}}
								>
									{buyerSelected?.id === item.id && (
										<BiCheck className={classes.checkmarkStyle} />
									)}
									<BuyerCard buyer={item} />
								</div>
							))}
						</div>
					</Mobile>
					<Desktop>
						<div
							style={{ display: "flex", width: "100%", paddingTop: ".5rem" }}
						>
							<BuyerSearchTable items={data.items} />
						</div>
					</Desktop>
					<PageNumbers
						totalPages={numberOfPages}
						currentPage={buyerSearchQuery.pageNumber}
						setPage={(num) => {
							setBuyerSearchQuery({
								...buyerSearchQuery,
								pageNumber: num,
							});
						}}
					/>
				</>
			)}
			{buyerSelected && (
				<div className={classes.submitButtons}>
					<Button onClick={() => addBuyer(buyerSelected)} variant="brand">
						Add Buyer
					</Button>
				</div>
			)}
		</div>
	);
};

const PageNumbers = ({
	totalPages,
	currentPage,
	setPage,
}: {
	totalPages: number;
	currentPage: number;
	setPage: (num: number) => void;
}) => {
	if (totalPages === 0) {
		return <></>;
	}
	return (
		<div className={classes.pageNumbers}>
			<Button
				disabled={currentPage === 0}
				onClick={() => setPage(currentPage - 1)}
			>
				<FaArrowLeft />
			</Button>
			<p>{currentPage}</p>
			<Button
				disabled={currentPage === totalPages - 1 || currentPage === totalPages}
				onClick={() => setPage(currentPage + 1)}
			>
				<FaArrowRight />
			</Button>
		</div>
	);
};

const createSearchQuery = (input: string) => {
	const { lastName, firstName } = formatName(input);
	const payload: BuyerSearchPayload = {
		address: input,
		firstName,
		lastName,
		institutionName: input,
		pageNumber: 0,
		pageSize: 15,
		phoneNumber: phoneNumber(input),
	};
	return payload;
};

const formatName = (input: string) => {
	if (input.includes(" ")) {
		const split = input.split(" ");
		return { firstName: split[0], lastName: split[split.length - 1] };
	}
	return { firstName: input, lastName: input };
};

const phoneNumber = (input: string) => {
	if (input.length <= 10) {
		return input;
	}
	return "";
};

const AddBuyerCell = ({ item }: { item?: Buyer }) => {
	const { saleId } = useSaleId();
	const { data: sale } = useGetSaleByIdQuery(saleId);
	const [trigger] = useUpdateSaleMutation();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const buyerInSale =
		sale?.buyerInterests &&
		sale.buyerInterests.filter((buyer) => buyer.id === item?.id).length > 0;
	const onAddBuyer = async () => {
		if (sale) {
			setIsLoading(true);
			let buyers = [...sale.buyerInterests];
			if (item) {
				buyers.push(item);
			}
			await trigger({
				...sale,
				buyerInterests: buyers,
			});
			setIsLoading(false);
		}
	};

	return (
		<DataTableCell title={buyerInSale ? "In Sale" : "Add Buyer"}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				{!isLoading ? (
					<>
						{buyerInSale ? (
							<BiCheck
								title="In Sale"
								style={{
									height: 20,
									width: 20,
									fill: "grey",
								}}
							/>
						) : (
							<BiPlus
								title="Add Buyer"
								style={{
									height: 20,
									width: 20,
									cursor: "pointer",
									fill: "blue",
								}}
								onClick={onAddBuyer}
							/>
						)}
					</>
				) : (
					<>
						<FaSpinner
							style={{
								height: 20,
								width: 20,
								fill: "grey",
							}}
							className={classes.spinner}
						/>
					</>
				)}
			</div>
		</DataTableCell>
	);
};
AddBuyerCell.displayName = DataTableCell.displayName;

const BuyerSearchTable = ({ items }: { items: Buyer[] }) => {
	const cols = Object.values(buyerColumnConfig);
	return (
		<DataTable
			style={{
				fontSize: ".8em",
				overflow: "auto",
				minWidth: "700px",
			}}
			items={items}
			id="BuyerTable"
			fixedLayout
			columnBordered
		>
			<DataTableColumn width="30px">
				<AddBuyerCell />
			</DataTableColumn>
			{cols.map((column) => {
				return (
					<DataTableColumn
						key={column.property}
						width={column?.width ? column.width : "50px"}
						label={column?.label ? column.label : ""}
						property={column.property}
					>
						{"component" in column && React.createElement(column.component)}
					</DataTableColumn>
				);
			})}
		</DataTable>
	);
};
