import { gatewayApi } from "@/ReduxToolkit/GatewayApi";
import { selectAsset } from "./selectors";
import { createPatch } from "@/Utilities/createPatch";
import { merge } from "lodash";
import {
	cacheOfflineAssets,
	doneSyncing,
	offlineDetected,
	onlineDetected,
	onOffline,
	onOnline,
	selectIsOnline,
	setOffline,
	setOnline,
	startSyncing,
	syncAssetDetails,
	syncAssetDetailsDone,
	syncConditionReports,
	syncConditionReportsDone,
	syncDocumentation,
	syncDocumentationDone,
	syncMedia,
	syncMediaDone,
} from "@/ReduxToolkit/offlineAssetSlice";
import { addAssetDetailConcurrencyError } from "@/features/offline-use/api/endpoints/syncAssetDetails";
import { offlineAssetEditsDB } from "@/storage";
import { listenerMiddleware } from "@/ReduxToolkit/listenerMiddleware";
import { createAction, isAnyOf } from "@reduxjs/toolkit";
import { applyPatch } from "fast-json-patch";

const assetDetailEndpoints = (builder) => ({
	patchAsset: builder.mutation({
		queryFn: async (
			{ id, shouldCheckConcurrency, ...data },
			{ dispatch, getState, signal },
			_extra,
			baseQuery
		) => {
			const offlineAssetResult =
				gatewayApi.endpoints?.getOfflineAssets?.select()(getState());

			const offlineAssets = offlineAssetResult?.data;
			const isOfflineAsset = offlineAssets?.ids?.includes(id);

			let patch;

			if (isOfflineAsset) {
				dispatch(
					gatewayApi.util.updateQueryData("getAsset", id, (draft) => {
						merge(draft, data);
					})
				);
				const currentAsset = await getAssetCache(id);
				patch = createPatch(currentAsset, data, shouldCheckConcurrency);
			} else {
				const currentAsset = selectAsset(id)(getState());
				patch = createPatch(currentAsset, data, shouldCheckConcurrency);
				dispatch(
					gatewayApi.util.updateQueryData("getAsset", id, (draft) => {
						const excludingWorksheetStatus =
							data?.marketingInfo?.pricingWorksheets?.[0]?.approvalStatus !==
							undefined;

						if (excludingWorksheetStatus) {
							merge(draft, {
								...data,
								marketingInfo: {
									marketingInfo: {
										pricingWorksheets: [
											{ ...data, approvalStatus: draft.approvalStatus },
										],
									},
								},
							});
						} else {
							merge(draft, data);
						}
					})
				);
			}

			const result = await baseQuery({
				url: `api/HomeDetails/${id}`,
				method: "PATCH",
				headers: {
					"content-type": "application/json-patch+json",
				},
				body: patch,
			});

			if (result.error) {
				if (result.error.status === 409) {
					await dispatch(
						addAssetDetailConcurrencyError.initiate({
							id,
							data: { id, patch },
						})
					);
				}

				return {
					error: result.error,
					meta: { patch },
				};
			}
			return result;
		},
		invalidatesTags: (_res, _error, { id }) => {
			return [{ type: "Asset", id }, "AssetList"];
		},
		extraOptions: { maxRetries: 0 },
	}),
});

gatewayApi.injectEndpoints({
	endpoints: assetDetailEndpoints,
	overrideExisting: true,
});

export const {
	useGenerateDescriptionMutation,
	usePatchAssetMutation,
	endpoints: { patchAsset, generateDescription },
} = gatewayApi;

const checkOnlineStatus = createAction("offline/checkOnlineStatus");

listenerMiddleware.startListening({
	matcher: isAnyOf(onlineDetected, offlineDetected, onOffline),
	effect: async (action, api) => {
		api.cancelActiveListeners();

		const isOnline = selectIsOnline(api.getOriginalState());

		if (onlineDetected.match(action) && !isOnline) {
			api.dispatch(checkOnlineStatus());
			return;
		}

		if (offlineDetected.match(action) && isOnline) {
			api.dispatch(setOffline());
			api.dispatch(gatewayApi.util.invalidateTags(["OfflineUse"]));
			api.dispatch(checkOnlineStatus());
			return;
		}

		if (onOffline.match(action)) {
			api.dispatch(setOffline());
			api.dispatch(gatewayApi.util.invalidateTags(["OfflineUse"]));
			return;
		}

		return;
	},
});

listenerMiddleware.startListening({
	matcher: isAnyOf(onOnline, checkOnlineStatus, onOffline),
	effect: async (action, api) => {
		api.cancelActiveListeners();

		if (onOffline.match(action)) {
			api.dispatch(
				gatewayApi.endpoints.live.initiate(undefined, { forceRefetch: true })
			);
			return;
		}

		const task = api.fork(async (forkApi) => {
			api.dispatch(
				gatewayApi.endpoints.live.initiate(undefined, { forceRefetch: true })
			);

			const [resultAction] = await api.take(
				(action) =>
					gatewayApi.endpoints.live.matchFulfilled(action) ||
					gatewayApi.endpoints.live.matchRejected(action)
			);

			return gatewayApi.endpoints.live.matchFulfilled(resultAction);
		});

		const checkOnlineResult = await task.result;
		const online = checkOnlineResult.value;

		if (online) {
			api.dispatch(setOnline());
			api.dispatch(cacheOfflineAssets());
			api.dispatch(gatewayApi.util.invalidateTags(["OfflineUse"]));
		} else {
			const retryDelay = api.fork(async (forkApi) => {
				await forkApi.delay(5000);
				return checkOnlineStatus();
			});

			const retryResult = await retryDelay.result;

			if (retryResult.status === "ok") {
				api.dispatch(retryResult.value);
			}
		}
	},
});

listenerMiddleware.startListening({
	matcher: isAnyOf(startSyncing, onOffline, offlineDetected),
	effect: async (action, api) => {
		api.cancelActiveListeners();

		if (onOffline.match(action) || offlineDetected.match(action)) {
			api.dispatch(setOffline());
			return;
		}

		const { assetDetails, conditionReports, documentation, mediaUploads } =
			action.payload;

		const assetDetailsSync = api.fork(async (forkApi) => {
			if (!assetDetails) return true;
			api.dispatch(syncAssetDetails());
			return await api.condition(syncAssetDetailsDone.match);
		});

		const mediaSync = api.fork(async (forkApi) => {
			if (!mediaUploads) return true;
			api.dispatch(syncMedia());
			return await api.condition(syncMediaDone.match);
		});

		const conditionReportSync = api.fork(async (forkApi) => {
			if (!conditionReports) return true;
			api.dispatch(syncConditionReports());
			return await api.condition(syncConditionReportsDone.match);
		});

		const documentationSync = api.fork(async (forkApi) => {
			if (!documentation) return true;
			api.dispatch(syncDocumentation());
			return await api.condition(syncDocumentationDone.match);
		});

		await Promise.allSettled([
			await assetDetailsSync.result,
			await mediaSync.result,
			await conditionReportSync.result,
			await documentationSync.result,
		]);

		api.dispatch(doneSyncing());
	},
});

listenerMiddleware.startListening({
	actionCreator: syncAssetDetails,
	effect: async (action, api) => {
		api.cancelActiveListeners();

		const editIds = await offlineAssetEditsDB.keys();

		if (editIds.length === 0) {
			api.dispatch(syncAssetDetailsDone());
			return;
		}

		const updates = await Promise.all(
			editIds.map(async (id) => {
				const asset = await getAssetCache(id);
				const edit = await offlineAssetEditsDB.getItem(id);
				const { newDocument } = applyPatch(asset, edit);

				const update = api.dispatch(patchAsset.initiate(newDocument));

				const unsubscribe = () => {
					update.unsubscribe();
				};

				try {
					await update.unwrap();
					return { unsubscribe };
				} catch (error) {
					if (error.status === 409) {
						return { unsubscribe };
					}
					return { error, unsubscribe };
				}
			})
		);

		updates.forEach((update) => update.unsubscribe());

		api.dispatch(syncAssetDetailsDone());
	},
});

listenerMiddleware.startListening({
	type: "assetCacheDidUpdate",
	effect: async (action, api) => {
		await api.dispatch(
			gatewayApi.util.upsertQueryData(
				"getAsset",
				action.payload.id,
				action.payload
			)
		);
	},
});

async function getAssetCache(id) {
	const cache = await caches.open("offline-asset");
	const request = await cache
		.keys()
		.then((keys) => keys.find((key) => key.url.match(id)));

	const response = await cache.match(request);

	const asset = await response.clone().json();

	return asset;
}
