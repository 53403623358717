import { Desktop, Mobile } from "@/Responsive";
import { BuyerTable } from "./BuyerTable";
import { BuyerCards } from "./BuyerCards";
import { BuyerHeader } from "./BuyerHeader";

export const Buyer = () => {
	return (
		<div>
			<BuyerHeader />
			<Desktop>
				<BuyerTable />
			</Desktop>
			<Mobile>
				<BuyerCards />
			</Mobile>
		</div>
	);
};
